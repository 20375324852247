export default {
  name: 'woss-photo-theme',
  rounding: 4,
  spacing: 24,
  defaultMode: 'dark',
  global: {
    colors: {
      brand: {
        dark: '#29961F',
        light: '#29961F',
      },
      background: {
        dark: '#131414',
        light: '#dddddd',
      },
      'background-back': {
        dark: '#101DAA',
        light: '#efefef',
      },
      'background-front': {
        dark: '#353535',
        light: '#5562ED',
      },
      'background-contrast': {
        dark: '#0014EF',
        light: '#11111111',
      },
      text: {
        dark: '#F1F1F1',
        light: '#333333',
      },
      'text-strong': {
        dark: '#F1F1F1',
        light: '#000000',
      },
      'text-weak': {
        dark: '#F1F1F1',
        light: '#444444',
      },
      'text-xweak': {
        dark: '#F1F1F1',
        light: '#666666',
      },
      border: {
        dark: '#444444',
        light: '#CCCCCC',
      },
      control: 'brand',
      'active-background': 'background-contrast',
      'active-text': 'text-strong',
      'selected-background': 'brand',
      'selected-text': 'text-strong',
      'status-critical': '#FF4040',
      'status-warning': '#FFAA15',
      'status-ok': '#00C781',
      'status-unknown': '#CCCCCC',
      'status-disabled': '#CCCCCC',
      'graph-0': 'brand',
      'graph-1': 'status-warning',
    },
    font: {
      family: 'Helvetica',
    },
    active: {
      background: 'active-background',
      color: 'active-text',
    },
    hover: {
      background: 'active-background',
      color: 'active-text',
    },
    selected: {
      background: 'selected-background',
      color: 'selected-text',
    },
  },
  chart: {},
  diagram: {
    line: {},
  },
  meter: {},
  layer: {
    background: {
      dark: '#646665',
      light: '#dddddd',
    },
  },
}
