import { Grommet } from 'grommet'
import React from 'react'
import GlobalStyle from './theme/GlobalStyle'
import theme from './theme/theme'

export default function Root({ children }) {
  React.useEffect(() => {
    console.log('loading...')
  }, [])

  return (
    <Grommet theme={theme} full>
      <GlobalStyle />
      {children}
    </Grommet>
  )
}
