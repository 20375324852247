// custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"
// normalize CSS across browsers
// import "./src/normalize.css"
// custom CSS styles
// import "./src/style.css"

// Highlighting for code blocks
// import "prismjs/themes/prism.css"
//
import React from 'react'
import Root from './src/Root'

// Logs when the client route changes
// export const onRouteUpdate = ({ location, prevLocation }) => {
// console.log('new pathname', location.pathname)
// console.log('old pathname', prevLocation ? prevLocation.pathname : null)
// }

export const wrapRootElement = (props) => {
  return <Root>{props.element}</Root>
}
