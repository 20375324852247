module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-image@1.4.0_62daf41d3ef9c833189c247f10657c94/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@3.4.0_gatsby@3.4.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/logo.png","name":"Woss Photo","short_name":"woss-photo","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"70f154d69a83e9b746494a7b86ab19f6"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@4.4.0_48f949a5487791cac316034eab95c894/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
